import * as React from "react";
import "./CleverAiSupportPage.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTitle } from "../../hooks/useTitle";
import cancelSubscriptionImage1 from "../../assets/images/cancel-subscription-1.jpeg";
import cancelSubscriptionImage2 from "../../assets/images/cancel-subscription-2.jpeg";
import cancelSubscriptionImage3 from "../../assets/images/cancel-subscription-3.jpeg";

type OwnProps = {};

type Props = OwnProps;

const CleverAiSupportPage: React.FC<Props> = (props) => {
  const {} = props;
  useTitle("Neurolify - Apps - Clever AI - Support");
  return (
    <Container fluid className="CleverAiSupportPage">
      <Row className={"p-5"}>
        <Col>
          <h1>Clever AI - Support</h1>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "left" }}>
          <h2>Frequently Asked Questions</h2>
          <h3>How to manage or cancel subscription?</h3>
          <p>
            To manage or cancel a subscription on your mobile device, simply
            follow these steps:
          </p>
          <p>
            Access your mobile application, navigate to the 'Me' page located in
            the bottom right navigation bar, and proceed to tap on the account
            button situated in the center of the screen, as illustrated in the
            accompanying picture guide.
          </p>
          <Col className={"col-lg-2 col-md-4 col-sm-12"}>
            <div>
              <img
                className="iphoneImage"
                src={cancelSubscriptionImage1}
                alt={"clever-ai-logo"}
              />
            </div>
          </Col>
          <p>
            Afterward, the account screen will appear, where you can choose
            'Delete account' from the pop-up menu, following the instructions
            shown in the picture guide.
          </p>
          <Col className={"col-lg-2 col-md-4 col-sm-12"}>
            <div>
              <img
                className="iphoneImage"
                src={cancelSubscriptionImage2}
                alt={"clever-ai-logo"}
              />
            </div>
          </Col>
          <p>
            Here, you'll encounter another pop-up. At the bottom of this pop-up,
            you'll find the option to click on the "Manage subscription" button,
            as shown in the picture. Clicking on this button will redirect you
            out of the mobile application to your mobile settings, where you can
            select the subscription for the Clever AI application and cancel it.
            Note that after cancellation, you can still use any remaining time
            of your subscription, if any remains. You will still be considered a
            pro user, and your number of coins should remain the same for the
            duration of your valid subscription. It won't renew automatically.
            However, any extra coins gained as a result of being a pro user will
            be removed the day your subscription ends if you haven't used them
            during the remaining subscription period.
          </p>
          <Col className={"col-lg-2 col-md-4 col-sm-12"}>
            <div>
              <img
                className="iphoneImage"
                src={cancelSubscriptionImage3}
                alt={"clever-ai-logo"}
              />
            </div>
          </Col>
          <p>
            Note that here you also have the option to change your subscription.
            If you wish to select a different subscription option, simply choose
            another one. Alternatively, if you want to cancel your subscription,
            tap on "Cancel Subscription." It's important to note that if you
            don't see the "Cancel Subscription" option, it indicates that the
            subscription has already been canceled and will not renew.
          </p>
          <h3>How to do refund on IOS?</h3>
          <p>To request a refund on your Apple device, follow these steps:</p>
          <p>Sign in to reportaproblem.apple.com.</p>
          <p>Choose "I'd like to" and select "Request a refund."</p>
          <p>
            Indicate the reason for your refund request and proceed to the next
            step.
          </p>
          <p>
            Choose the app, subscription, or other item that you want to refund,
            and then select "Submit."
          </p>
          <p>
            If you want to cancel a subscription that you no longer need, you
            can do so by following the same steps.
          </p>
          <h3>
            How to do delete your account and all data associated with it?
          </h3>
          <p>
            Important note: If you delete your account, all associated data,
            including chat history and favorites, will be permanently deleted,
            and your Apple token will be revoked and you are no longer
            associated with your app.. You will also be automatically logged out
            from the mobile application, and there will be no way to recover
            your account later on. It is also necessary to cancel any active
            subscriptions before deleting your account to avoid accidental
            charges.
          </p>
          <p>
            To delete your account and all associated data, follow these steps:
          </p>
          <p>Open the mobile application.</p>
          <p>
            Click on the settings logo in the top right corner of the main page.
          </p>
          <p>Select "Account" from the menu.</p>
          <p>
            If you have an active subscription with us, you will see a "Manage
            subscription" button.
          </p>
          <p>
            Click on it to cancel your subscription before deleting your
            account.
          </p>
          <p>
            If you accidentally click on the "Delete" button while you have an
            active subscription, the system will guide you to cancel your
            subscription first before proceeding with the deletion process.
          </p>
          <p>
            After canceling your subscription, wait for about 10 minutes for the
            cancellation to become effective.
          </p>
          <p>
            If you don't have an active subscription, you will only see the
            "Delete" option.
          </p>
          <p>
            Then, go to "Account" and click on the "Delete" button to start the
            deletion process.
          </p>
          <p>
            We will ask you to login again with your email to confirm your
            identity and complete the deletion.
          </p>
          <p>
            You will receive a confirmation of deletion immediately, and you
            will be signed out automatically from the mobile application. You
            will also receive an email from Apple shortly, informing you that
            your API access has been revoked.
          </p>
          <h3>
            How can I restore my purchase after signing out and signing back in?
          </h3>
          <p>
            Please keep in mind that your subscription is linked to your Apple
            ID. If you sign out of the mobile application, you will need to
            restore your purchase after signing back in to continue using your
            active subscription. Additionally, if you use a new ID to log in on
            your device and perform a restore purchase with the new ID, your
            subscription will transfer to the new ID.
          </p>
          <p>To restore your purchase</p>
          <p>Open the mobile application</p>
          <p>
            Click on the settings logo in the top right corner of the main page
          </p>
          <p>Select "Account" from the menu</p>
          <p>Click on "Restore Purchase" to reactivate your subscription."</p>
        </Col>
      </Row>
      <Row className={"p-5"}>
        <Col>
          <p>
            If you have any questions or feedback about our products, please
            contact us at neurolify@neurolify.com.
          </p>
          <p>Our team is always happy to hear from you!</p>
          <div style={{ marginTop: "40px" }}>
            <Button href="mailto:neurolify@neurolify.com">
              Send us an email
            </Button>
          </div>
          <div style={{ marginTop: "40px" }}>
            <Button href="https://t.me/CleverAISupport">
              Telegram Support Chat
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CleverAiSupportPage;
