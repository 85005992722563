import * as React from "react";
import './Footer.css';


type OwnProps = {};

type Props = OwnProps;

const Footer: React.FC<Props> = props => {
    const {} = props;
    return (
        <footer className="Footer">
            <small>
                2023 © Neurolify - All rights reserved.
            </small>
        </footer>
    );
};


export default Footer;
