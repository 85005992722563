import * as React from "react";
import './CleverAiPage.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import {useTitle} from "../../hooks/useTitle";
import cleverAiLogoImage from '../../assets/images/clever-ai-logo.png';
import downloadAppStoreImage from '../../assets/images/download-on-the-app-store.svg';
import downloadGooglePlayImage from '../../assets/images/download-on-the-google-play.png';
import iphoneImage from '../../assets/images/I 14 1.jpg';
import iphoneImage2 from '../../assets/images/I 14 2.jpg';
import iphoneImage3 from '../../assets/images/I 14 3.jpg';
import iphoneImage4 from '../../assets/images/I 14 4.jpg';
import iphoneImage5 from '../../assets/images/I 14 5.jpg';
import iphoneImage6 from '../../assets/images/I 14 6.jpg';
import ReactPlayer from "react-player";

type OwnProps = {};

type Props = OwnProps;

const CleverAiPage: React.FC<Props> = props => {
    const {} = props;
    useTitle("Neurolify - Apps - Clever AI");
    return (
        <Container fluid className="CleverAiPage">
            <Row className={'p-2'}>
                <Col>
                    <div>
                        <img className='cleverAiLogoImage' src={cleverAiLogoImage} alt={'clever-ai-logo'}/>
                    </div>
                </Col>
            </Row>
            <Row className={'p-2'}>
                <Col>
                    <h1>Clever AI Hub</h1>
                </Col>
            </Row>
            <Row className={'p-2'}>
                <Col>
                    <Row className={'p-2'}>
                        <Col>
                            <a href="https://play.google.com/store/apps/details?id=com.turbofasttools.geniusai"
                               target={'_blank'}>
                                <img className='playStoreImage' src={downloadGooglePlayImage}
                                     alt={'play-store-image'}/>
                            </a>
                        </Col>
                    </Row>
                    <Row className={'p-2'}>
                        <Col>
                            <a href="https://apps.apple.com/ca/app/clever-ai-chatbot-assistant/id1667722375"
                               target={'_blank'}>
                                <img className='appStoreImage' src={downloadAppStoreImage} alt={'app-store-image'}/>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={'p-5'}>
                <Col>
                    <Row className={'p-2'}>
                        <Col className={'col-lg-2 col-md-4 col-6'}>
                            <img className='iphoneImage' src={iphoneImage} alt={'clever-ai-logo'}/>
                        </Col>
                        <Col className={'col-lg-2 col-md-4 col-6'}>
                            <img className='iphoneImage' src={iphoneImage2} alt={'clever-ai-logo'}/>
                        </Col>
                        <Col className={'col-lg-2 col-md-4 col-6'}>
                            <img className='iphoneImage' src={iphoneImage3} alt={'clever-ai-logo'}/>
                        </Col>
                        <Col className={'col-lg-2 col-md-4 col-6'}>
                            <img className='iphoneImage' src={iphoneImage4} alt={'clever-ai-logo'}/>
                        </Col>
                        <Col className={'col-lg-2 col-md-4 col-6'}>
                            <img className='iphoneImage' src={iphoneImage5} alt={'clever-ai-logo'}/>
                        </Col>
                        <Col className={'col-lg-2 col-md-4 col-6'}>
                            <img className='iphoneImage' src={iphoneImage6} alt={'clever-ai-logo'}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={'p-2 m-4'} style={{textAlign: 'center', justifyContent: 'center'}}>
                <Col className={'col-md-3 col-sm-12'}>
                    <ReactPlayer url='https://www.youtube.com/shorts/MAf-hbE9HFE' width={'100%'}/>
                </Col>
                <Col className={'col-md-9 col-sm-12'} style={{textAlign: 'left'}}>
                    <h1>Introducing Clever AI</h1>
                    <p>The ultimate AI assistant for all your needs. Our smart and clever Chatbot is built on top of
                        ChatGPT and GPT-4 API, offering unlimited writing conversations with OpenAI's GPT3 and GPT4
                        technologies. With AI CHAT, you can ask any question and get an immediate response from our
                        genius AI-powered chatbot, making it the most reliable and advanced chatbot in the market.</p>

                    <h2>Embark on an unprecedented AI odyssey</h2>
                    <p>With Clever AI Hub, your central hub for groundbreaking AI tools and a revolutionary AI
                        experience. Immerse yourself in a world where Grok, GPT-4, OpenAI, Gemini, DALL-E, Claude, and
                        more converge seamlessly within a singular, user-centric platform. Explore freely and shape your
                        personalized AI assistant with the prowess of any AI model, whether it's ChatGPT, Llama2,
                        Gemini, DALL-E, Stable diffusion, or others. Unlock the limitless potential of AI tools without
                        the hassle of multiple installations or payments. From precision-question answering to
                        generating art, essays, text, audio, and video, Clever AI Hub stands as your unparalleled
                        one-stop destination for a fast, customizable, and personalized AI adventure. Welcome to the
                        future, where innovation meets exploration effortlessly, all within the Clever AI Hub – your
                        ultimate AI companion.</p>

                    <h3>Seamless Exploration</h3>
                    <p>Seamlessly navigating diverse AI tools like Whisper, GPT-4, OpenAI, Gemini, DALL-E, Claude, and
                        more—all within one platform. From text-to-image generators to advanced chatbots, AI speech
                        recognition, and text-to-video models, experience the richness of AI diversity. Clever AI Hub
                        breaks constraints, opening gateways to enterprise and community-provided tools. Explore,
                        experiment, and innovate with different AI systems, redefining your interaction with artificial
                        intelligence.</p>

                    <h3>Freely Explore with Renewal Coins</h3>
                    <p>Dive into a realm of unfettered exploration within the AI Hub using daily renewable coins.
                        Immerse yourself in the world of AI tools, experiencing their capabilities without any upfront
                        commitments. Pay only when you're convinced and ready to unlock the full potential of your AI
                        journey.</p>

                    <h3>One App for All Your AI Needs</h3>
                    <p>Explore the vast realm of AI effortlessly with Clever AI Hub – the one-stop destination where you
                        can access all assistants and AI tools in one place. Discover, create, and innovate freely
                        without any upfront commitments. Pay only if you find yourself immersed and ready to make the
                        most of it. Bid farewell to the complexity of multiple installations and explore the world of AI
                        seamlessly within a single app.</p>

                    <h3>AI News and Updates</h3>
                    <p>Stay at the forefront of the rapidly evolving and dynamic world of AI systems and tools with
                        Clever AI Hub.</p>

                    <h3>Personal AI Assistant Creation</h3>
                    <p>Craft your own AI assistant effortlessly by leveraging the prowess of AI models like ChatGPT,
                        Gemini, DALL-E, and more. Whether you desire text-based intelligence, voice capabilities, image
                        processing, or a unique mix of them all, Clever AI Hub empowers you to shape your assistant with
                        ease.</p>
                    <p>Explore the endless possibilities of creating a hybrid AI assistant that uses ChatGPT for text,
                        and voice capabilities from Gemini, image processing with DALL-E, and beyond. Clever AI Hub
                        provides you with the flexibility to combine various AI tools, allowing you to customize and
                        innovate your AI assistant to suit your unique needs.</p>

                    <h3>Shared Creations and Collaborative Interaction</h3>
                    <p>Not only can you create your personal AI assistant, but Clever AI Hub also enables you to share
                        your innovations with the community. Interact, collaborate, and explore AI assistants crafted by
                        others. Engage in a platform where users can seamlessly share, review, and enhance their AI
                        creations, fostering a dynamic and collaborative environment within the Clever AI Hub
                        community.</p>
                </Col>
            </Row>
            <Row className={'p-2'}>
                <Col>
                    <Button href="/apps/clever-ai/terms">Terms of Service</Button>
                </Col>
            </Row>
            <Row className={'p-2'}>
                <Col>
                    <Button href="/apps/clever-ai/privacy-policy">Privacy Policy</Button>
                </Col>
            </Row>
            <Row className={'p-2'}>
                <Col>
                    <Button href="/apps/clever-ai/support">Support</Button>
                </Col>
            </Row>
        </Container>
    );
};


export default CleverAiPage;
