import * as React from "react";
import './MainPage.css';
import {Col, Container, Row} from "react-bootstrap";
import {useTitle} from "../../hooks/useTitle";


type OwnProps = {};

type Props = OwnProps;

const MainPage: React.FC<Props> = props => {
    const {} = props;
    useTitle("Neurolify - Home");
    return (
        <Container fluid className="MainPage">
            <Row>
                <Col>
                    <div className='p-5'>
                        <p>
                            Neurolify is a company that specializes in artificial intelligence. We are a team of
                            engineers,
                            designers, and data scientists who are passionate about building products that make life
                            easier. Our
                            mission is to create AI-powered tools that help people live more productive lives. We
                            believe that
                            technology should be used for good, not evil. That's why we're committed to building
                            products that
                            empower individuals and organizations to do their best work. Our goal is to make it easier
                            for you to
                            get things done so you can focus on what matters most.
                        </p>
                        <p>
                            We're always looking for talented people who share our values and want to join us in making
                            the world a
                            better place through technology. If you're interested in working with us, please send your
                            resume to
                            neurolify@neurolify.com.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>

    );
};


export default MainPage;
