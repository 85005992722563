import * as React from "react";
import './CleverAiTermsPage.css';
import {Col, Container, Row} from "react-bootstrap";
import {useTitle} from "../../hooks/useTitle";


type OwnProps = {};

type Props = OwnProps;

const CleverAiTermsPage: React.FC<Props> = props => {
    const {} = props;
    useTitle("Neurolify - Apps - Clever AI - Terms of Use");
    return (
        <Container fluid className="CleverAiTermsPage">
            <Row className={'p-5'}>
                <Col>
                    <h1>Clever AI - Terms of Use</h1>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col>
                    <p>
                        Last updated March 03, 2024
                    </p>
                    <p>
                        1.Terms
                        When you download, browse, access or use this mobile application, you are agreeing to follow and
                        be bound by the Terms and Conditions of Use outlined by us. We may change these terms and
                        conditions at any time, and it is your responsibility to stay updated with any changes made. If
                        you do not agree to any part of these terms and conditions, you must stop using the Mobile
                        Application and its services immediately. By continuing to use the Mobile Application, you are
                        indicating your acceptance of these Terms and Conditions of Use, including any modifications
                        that may be made in the future.
                        Welcome to Clever AI Hub created by NEUROLIFY INc.
                        a platform that enables users to create bots, assistants, and skins using our AI language models and interact with them. 
                        These Terms of Use govern your use of the NEUROLIFY platfrom and its services. By using our application, you agree to comply with these terms. 
                        If you do not agree with any part of these terms, you must discontinue using our services immediately.
                    </p>
                    <p>
                        2. Definitions
                        These Terms and Conditions of Use define specific meanings for certain capitalized terms, unless
                        the context indicates otherwise.
                    </p>
                    <p>
                        2.1. User-Generated Content (UGC)
                        User-Generated Content (UGC) encompasses all content created, uploaded, 
                        or shared by users on the NEUROLIFY mobile application. This includes personalized AI 
                        creations such as bots, assistants, and images provided within the NEUROLIFY platform. 
                        The specific names of these creations may vary in the future, but they remain personalized 
                        AI creations generated by users through the NEUROLIFY platform.
                    </p>
                    <p>
                        2.2. Prohibited Content
                        Prohibited Content refers to any material that violates these Terms of Use, encompassing but not limited to unlawful, 
                        harmful, threatening, abusive, harassing, defamatory, obscene, vulgar, hateful, pornographic, or harassing content.
                    </p>
                    <p>
                        2.3. NEUROLIFY Store
                        The marketplace within the NEUROLIFY platfrom where users can share 
                        and sell their bots, assistants, and skins.
                    </p>
                    <p>
                        2.4.  NEUROLIFY Platform
                        The platform provided by NEUROLIFY, 
                        including but not limited to mobile applications, desktop applications,
                        web-based software or any of their software as services (SaaS), or any other type of software, 
                        where users can share and sell their bots, assistants, and skins.
                    </p>
                    <p>
                        2.5. Account - refers to a user's account that is established through the Registration process
                        on the Mobile Application.
                    </p>
                    <p>
                        2.6. Merchant - pertains to any organization or individual whose products can be bought through
                        the Mobile Application.
                    </p>
                    <p>
                        2.7. Services - refers to all of the services offered by NEUROLIFY INC to users through the
                        Mobile Application, while the term "Service" indicates any individual service provided among
                        them,
                    </p>
                    <p>
                        2.8. Users - refers to all individuals who utilize the Mobile Application, including yourself,
                        whereas "User" pertains to any one of these individuals.
                    </p>
                    <p>
                        2.9. Goods - refer to the items offered for sale on the Service.
                    </p>
                    <p>
                        2.10. Company - (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                        refers to NEUROLIFY INC.
                    </p>
                    <p>
                        2.11.  NEUROLIFY
                        NEUROLIFY INC, the provider of the NEUROLIFY platfrom and its services.
                    </p>
                    <p>
                        2.12. Application Store - refers to the digital distribution service, such as the Apple App Store
                        operated and developed by Apple Inc., or the Google Play Store operated and developed by Google
                        Inc., from which the Application has been downloaded.
                    </p>
                    <p>
                        2.13. Coins - Coins are virtual units within the application that users can utilize to obtain responses 
                        from various AI models, such as ChatGPT, Bard, DALL-E, and more.
                    </p>
                    <p>
                        2.14. Model - A model refers to a machine learning model employed by the Company within the application 
                        to fulfill user requests. Examples include ChatGPT, DALL-E, Bard, and more. These models are provided by NEUROLIFY for users to create bots, assistants, and skins.
                        Models serve as the backbone for delivering diverse AI capabilities, encompassing text, image, voice functionalities and more.
                        Each AI model may have a distinct coin price associated with its usage, providing users with flexibility and choice.
                    </p>
                    <p>
                        2.15. Application -  The term "Application" pertains to any mobile application developed and published 
                        by the Company, available for download on Google Play and Apple Store.
                    </p>
                    <p>
                        2.16. User
                        Any individual who utilizes the NEUROLIFY platfrom and its services.
                    </p>
                    <p>
                        3.General Matters Related to the Mobile Application and its Services
                    </p>
                    <p>
                        3.1. Validity of Terms and Conditions - The utilization of any Services and/or the Mobile
                        Application, as well as any Redemptions made, are bound by these Terms and Conditions of Use.
                    </p>
                    <p>
                        3.2. Mobile Application Usage Permission - In case you are not the individual responsible for
                        paying the bills of the mobile phone or handheld device that is being used to access the Mobile
                        Application, it is assumed that you have obtained permission from the bill payer to use the
                        Mobile Application.
                    </p>
                    <p>
                        3.3. Limitation of Usage - We reserve the right to restrict your usage of the Mobile Application
                        and its Services, including any portion of them, as well as your ability to make any purchases
                    </p>
                    <p>
                        3.4 Order Cancellation - We retain the right to decline or annul Your Order at any point, for
                        various grounds, including, but not restricted to:
                    </p>
                    <p>
                        Unavailability of Goods
                    </p>
                    <p>
                        Errors in the Goods' description or prices
                    </p>
                    <p>
                        Inaccuracies in Your Order
                    </p>
                    <p>
                        If we suspect any fraud, unauthorized or unlawful transaction.
                    </p>
                    <p>
                        4.Product Availability and Mistakes
                        4.1. We regularly update the range of Goods offered on the Service, which may result in pricing
                        errors, inaccurate descriptions, or unavailability. We may also experience delays in updating
                        information related to our Goods on the Service or in our advertisements on other websites.
                    </p>
                    <p>
                        We do not guarantee the accuracy or completeness of any information, including prices, product
                        images, specifications, and availability. We reserve the right to modify or update information,
                        and to correct errors, inaccuracies, or omissions without prior notice.
                    </p>
                    <p>
                        5. Price policy
                        5.1. The Company's price policy states that it can modify its prices before accepting an Order.
                        The Company may adjust the quoted prices after accepting an Order in the case of government
                        intervention, changes in customs duties, increased shipping charges, higher foreign exchange
                        costs, or any other factors beyond the Company's control that affect delivery. If such an event
                        occurs, You have the option to cancel Your Order.
                    </p>
                    <p>
                        6. Payments
                        All Goods purchased are subject to a one-time payment. Payment can be made through various
                        payment methods we have available, such as Visa, MasterCard, Affinity Card, American Express
                        cards or online payment methods such as Stripe.
                    </p>
                    <p>
                        Payment cards (credit cards or debit cards) are subject to validation checks and authorization
                        by Your card issuer. If we do not receive the required authorization, We will not be liable for
                        any delay or non-delivery of Your Order.
                    </p>
                    <p>
                        7. Subscriptions
                        7.1. Subscription period - Some parts of our Service are only accessible through a paid
                        Subscription, which you will be billed for in advance on a recurring basis. The billing
                        frequency ( weekly, monthly, or annually) depends on the type of Subscription plan you choose.
                    </p>
                    <p>
                        Your Subscription will automatically renew at the end of each period under the same conditions,
                        unless you cancel it or the Company terminates it.
                    </p>
                    <p>
                        7.2. Fee changes - The Company reserves the right to modify Subscription fees at any time and in
                        its sole discretion. If the Company decides to change the Subscription fees, the new fees will
                        become effective at the end of the current Subscription period.
                    </p>
                    <p>
                        7.2.1 Coin Adjustments - The Company reserves the right to change, decrease, or increase 
                        the number of coins allocated with a user's subscription at any time, with prior notice.
                    </p>
                    <p>
                        7.2.2 Model Cost Adjustments - The Company reserves the right to change the coin or cost required for 
                        each AI model at any time, with prior notice.
                    </p>
                    <p>
                        We will notify you of any change in Subscription fees with reasonable prior notice, giving you
                        the opportunity to cancel your Subscription before the new fees come into effect.
                    </p>
                    <p>
                        By continuing to use the Service after the new Subscription fee takes effect, you agree to pay
                        the modified fee amount.
                    </p>
                    <p>
                        7.3. Refund - Paid Subscription fees are generally non-refundable, except where required by law.
                    </p>
                    <p>
                        In certain cases, the Company may consider refund requests for Subscriptions on a case-by-case
                        basis, but such requests are subject to the Company's sole discretion.
                    </p>
                    <p>
                        If you purchased the Subscription through an In-app purchase, the refund policy of the
                        Application Store will apply. To request a refund, you will need to contact the Application
                        Store directly.
                    </p>
                    <p>
                        In the event of account deletion, if you have an active subscription, we ask that you cancel
                        your auto-renewal before deleting your account to prevent accidental charges. However, please
                        note that your current active subscription will be non-refundable.
                    </p>
                    <p>
                        7.4. Free Trial - The Company may offer a Subscription with a limited-time Free Trial at its
                        discretion.
                    </p>
                    <p>
                        To sign up for the Free Trial, you may need to provide your billing information.
                    </p>
                    <p>
                        If you do provide your billing information during the Free Trial sign-up, the Company will not
                        charge you until the trial period has ended. On the final day of the Free Trial, unless you have
                        canceled your Subscription, the Company will automatically charge you the applicable
                        Subscription fee for the plan you have chosen.
                    </p>
                    <p>
                        The Company reserves the right to modify or cancel the terms and conditions of the Free Trial
                        offer at any time without notice.
                    </p>
                    <p>
                        7.5. In-app Purchases - The Application may offer In-app Purchases, which allow you to buy
                        products, services, or Subscriptions within the Application.
                    </p>
                    <p>
                        You may be able to find more information on managing In-app Purchases in the Application Store's
                        terms and conditions or your Device's Help settings.
                    </p>
                    <p>
                        In-app Purchases are only usable within the Application and cannot be canceled after download or
                        redeemed for cash or other consideration.
                    </p>
                    <p>
                        If you encounter any issues with an In-app Purchase, please notify us, and we will investigate
                        the issue and provide a replacement or patch, as appropriate. We will not charge you for any
                        replacement or repair. If we are unable to replace or repair the In-app Purchase within a
                        reasonable time frame, we will authorize the Application Store to refund you the cost of the
                        relevant purchase, or you can request a refund directly from the Application Store.
                    </p>
                    <p>
                        You acknowledge that billing and transactions for In-app Purchases are handled by the
                        Application Store where you downloaded the Application and are subject to that store's terms and
                        conditions.
                    </p>
                    <p>
                        If you have any payment-related issues with In-app Purchases, you must contact the Application
                        Store directly.
                    </p>
                    <p>
                        8. Promotions
                        The Service may offer Promotions that are subject to separate rules from these Terms of Use.
                    </p>
                    <p>
                        Before participating in any Promotions, we advise you to review both the applicable rules and
                        our Privacy Policy. In the event that the rules for a Promotion conflict with these Terms, the
                        Promotion rules will take precedence.
                    </p>
                    <p>
                        9. Disclaimer and limitation of liability
                        9.1. The Mobile Application and Services, along with any information provided on the Mobile
                        Application and use of related facilities are offered on an "as is, as available" basis without
                        any express or implied warranties.
                    </p>
                    <p>
                        9.2. We disclaim all warranties, including but not limited to, warranties of merchantability,
                        quality, fitness for a particular purpose, accuracy, availability, non-infringement or any
                        implied warranties arising from usage of the Mobile Application or in relation to any
                        inaccuracies or omissions in the Mobile Application.
                    </p>
                    <p>
                        9.3. We do not guarantee that the Mobile Application will always be available, uninterrupted,
                        timely, secure, error-free, or free from computer virus or other harmful code. The Mobile
                        Application may also be affected by acts of God or other force majeure events.
                    </p>
                    <p>
                        9.4. We may use reasonable efforts to ensure the accuracy and up-to-date information on the
                        Mobile Application, but we make no warranties or representations as to its accuracy, timeliness,
                        or completeness.
                    </p>
                    <p>
                        9.5. We shall not be liable for any direct, indirect, incidental, special, consequential, or
                        punitive damages, however caused, resulting from or in connection with the Mobile Application
                        and Services, including your access to, use of, or inability to use the Mobile Application or
                        Services, reliance on or downloading from the Mobile Application and/or Services, or any delays,
                        inaccuracies, or errors in the information or in its transmission, even if we have been advised
                        of the possibility of such damages.
                    </p>
                    <p>
                        9.6. We shall not be liable in contract, tort (including negligence or breach of statutory
                        duty), or otherwise howsoever caused, for any indirect, consequential, collateral, special, or
                        incidental loss or damage suffered or incurred by you in connection with the Mobile Application
                        and these Terms and Conditions of Use.
                    </p>
                    <p>
                        9.7. These exclusions and limitations apply to the extent permitted by law. They do not affect
                        any of your statutory rights as a consumer that cannot be excluded or limited.
                    </p>
                    <p>
                        9.8. We acknowledge that electronic data transfers may be intercepted by others, and we cannot
                        warrant that data transfers or electronic mail transmitted to or from us will not be monitored
                        or read by others, notwithstanding our efforts to ensure that our system is secure.
                    </p>
                    <p>
                        10. User Accounts
                        By creating an account with us, you must provide accurate, complete, and up-to-date information
                        at all times. Failing to comply with this requirement constitutes a violation of these Terms,
                        which could lead to immediate termination of your account on our Service.
                    </p>
                    <p>
                        You are responsible for protecting the password you use to access the Service and for any
                        activity under your password, whether it occurs on our Service or on a third-party social media
                        service.
                    </p>
                    <p>
                        You must not reveal your password to any third party. You must notify us immediately if you
                        become aware of any security breaches or unauthorized use of your account.
                    </p>
                    <p>
                        You are not permitted to use the name of another individual or entity as your username, or a
                        name or trademark that is not legally available for use, or a name that is deemed offensive,
                        vulgar, or obscene. Such a use would require appropriate authorization.
                    </p>
                    <p>
                        11. Intellectual property rights
                        11.1 The content, images, artwork, logos, trademarks, and other materials displayed on the
                        Mobile Application are protected by copyright laws, international treaties, and other laws. They
                        belong to us or our suppliers and may not be copied, reproduced, sold, published, or distributed
                        without our express permission.
                    </p>
                    <p>
                        11.2 You are not granted any license or right to use any trademark displayed on the Mobile
                        Application without our written permission. Unauthorized use of any trademarks or content
                        displayed on the Mobile Application is prohibited.
                    </p>
                    <p>
                        11.3 We will take legal action against any unauthorized use of our trademarks, name, or symbols
                        to protect our rights. All rights not expressly granted here are reserved. Any other product and
                        company names mentioned on the Mobile Application may be the trademarks of their respective
                        owners.
                    </p>
                    <p>
                        12. User-Generated Content (UGC) Intellectual property rights, Revenue Sharing, Distribution rights and Responsibility
                        12.1 The content, images, artwork, logos, trademarks, and other materials displayed on the
                        Mobile Application are protected by copyright laws, international treaties, and other laws. They
                        belong to us or our suppliers and may not be copied, reproduced, sold, published, or distributed
                        without our express permission.
                    </p>
                    <p>
                        12.2 NEUROLIFY Store Policies
                        NEUROLIFY reserves the right to establish policies and guidelines for the NEUROLIFY Store, 
                        including revenue-sharing arrangements and distribution of commissions.
                    </p>
                    <p>
                        12.3 User-Generated Content (UGC)
                        Any content, including but not limited to bots, assistants, skins, or other digital creations known as UGC, 
                        developed or created by users within the NEUROLIFY platform ("User-Created Content"), 
                        shall be owned by NEUROLIFY INC.
                    </p>
                    <p>
                        12.4  User-Generated Content (UGC) Intellectual Property Rights 
                        NEUROLIFY INC shall have exclusive ownership of all intellectual property rights, 
                        including but not limited to copyrights, trademarks, patents, and trade secrets,
                        associated with the User-Generated Content. Users acknowledge and agree that
                        NEUROLIFY INC retains all rights, title, and interest in and to the User-Created Content.
                    </p>
                    <p>
                        12.5  Distribution Rights 
                        NEUROLIFY INC reserves the right to distribute, sublicense, and commercialize the 
                        User-Created Content across its platform, including but not limited to its application store 
                        and affiliated services, without further consent from the users who created such content.
                        NEUROLIFY INC retains all rights, title, and interest in and to the User-Generated Content.
                    </p>
                    <p>
                        12.6  Distribution Rights 
                        NEUROLIFY INC reserves the right to distribute, sublicense, and commercialize the 
                        User-Created Content across its platform, including but not limited to its application store 
                        and affiliated services, without further consent from the users who created such content.
                        NEUROLIFY INC retains all rights, title, and interest in and to the User-Generated Content.
                    </p>
                    <p>
                        12.7  Royalty Commission
                        NEUROLIFY INC may, at its discretion, provide a royalty commission to users who 
                        created the User-Generated Content. The royalty commission shall be 
                        determined by NEUROLIFY INC based on various factors, including but not limited 
                        to the popularity, usage, and monetization of the User-Generated Content.
                    </p>
                    <p>
                        12.8  Restrictions on Distribution
                        Users shall not distribute, sublicense, or otherwise make available their 
                        User-Generated Content outside of the NEUROLIFY platform without the express 
                        acknowledgment and authorization from NEUROLIFY INC. Any unauthorized distribution of 
                        User-Generated Content may result in legal action by NEUROLIFY INC.
                    </p>
                    <p>
                        12.9  Responsibility 
                        Users are solely responsible for the content they create, upload, or 
                        share on the NEUROLIFY mobile application. Users must ensure that their 
                        content complies with these Terms of Use and does not violate any third-party rights, 
                        including but not limited to copyrights, trademarks, privacy rights, or any applicable laws 
                        or regulations.
                    </p>
                    <p>
                        12.10 Prohibited Content 
                        Users are prohibited from creating, uploading, or sharing any prohibited content that is unlawful,
                        harmful, threatening, abusive, harassing, defamatory, obscene, vulgar, hateful, 
                        or otherwise objectionable.
                    </p>
                    <p>
                        12.11 Monitoring 
                        NEUROLIFY INC reserves the right, but not the obligation, to monitor, review, or remove any 
                        user-generated content that violates these Terms of Use or is otherwise deemed inappropriate, 
                        in NEUROLIFY INC's sole discretion.
                    </p>
                    <p>
                        12.12 Feedback 
                        NEUROLIFY INC may collect feedback from users regarding the user-generated content 
                        feature to improve the NEUROLIFY mobile application and its services. 
                        By providing feedback, users grant NEUROLIFY INC the right to use such feedback for any purpose without 
                        compensation to the user.
                    </p>
                    <p>
                        13. Indemnification
                        You agree to defend, indemnify, and hold us harmless from any claims, actions, suits, or
                        proceedings brought or threatened against us arising from (a) your use of the Services, (b) any
                        other party's use of the Services using your user ID, verification PIN, and/or any identifier
                        number allocated by TRBO FAST TOOLS INC, or (c) your violation of any of these Terms and
                        Conditions of Use. You also agree to pay for any damages, costs, and interest related to such
                        claims, actions, suits, or proceedings.
                        Users agree to indemnify and hold NEUROLIFY INC harmless from any claims, damages, liabilities, or 
                        expenses arising out of or related to the distribution or use of their User-Generated Content, 
                        including but not limited to any claims of infringement of third-party intellectual property rights.
                    </p>
                    <p>
                        14. Amendments
                        14.1. We may make changes to the contents of the Mobile Application, including the descriptions
                        and prices of goods and services advertised, without prior notice. We are not liable for any
                        errors or omissions in the Mobile Application's content.
                    </p>
                    <p>
                        14.2. We reserve the right to modify these Terms and Conditions of Use without notice. The
                        updated version will be published on the Mobile Application and will take effect from the date
                        of posting. It is recommended that you review these terms and conditions periodically as they
                        are legally binding for you.
                    </p>
                    <p>
                        15. Applicable law and jurisdiction
                        The Mobile Application can be accessed from all countries where local technology allows. Because
                        different places have different laws, by accessing the Mobile Application, you and we agree that
                        the laws of the Republic of Singapore will apply to all matters related to its use, without
                        regard to the conflicts of laws principles.
                    </p>
                </Col>
            </Row>

        </Container>
    );
};


export default CleverAiTermsPage;
