import * as React from "react";
import './CleverAiPolicyPage.css';
import {Col, Container, Row} from "react-bootstrap";
import {useTitle} from "../../hooks/useTitle";


type OwnProps = {};

type Props = OwnProps;

const CleverAiPolicyPage: React.FC<Props> = props => {
    const {} = props;
    useTitle("Neurolify - Apps - Clever AI - Privacy Policy");
    return (
        <Container fluid className="CleverAiPolicyPage">
            <Row className={'p-5'}>
                <Col>
                    <h1>Clever AI - Privacy Policy</h1>
                </Col>
            </Row>
            <Row style={{textAlign: 'left'}}>
                <Col>
                    <p>
                        Last updated March 03, 2024
                    </p>
                    <p>
                        This is the Privacy Policy for the mobile applications developed by NEUROLIFY INC and all
                        its versions (referred to as "us," "we," or "our"). This policy is subject to our Terms of Use,
                        which can be accessed at https://neurolify.com/apps/clever-ai/terms, and is incorporated into
                        them. Throughout this Privacy Policy, we use the term "Application" to refer to our products and
                        applications. By using the Application, you indicate your agreement with this Privacy Policy. If
                        you do not agree to the terms outlined in this Privacy Policy, please refrain from using the
                        Application.
                    </p>
                    <p>
                        Information collected by us
                        Personal Data When you register with us and install the Application on your device, we collect
                        personally identifiable information about you. As part of the registration process, we require
                        you to provide your email address, In some cases, we may offer the option to hide your email
                        address to provide additional privacy.
                    </p>
                    <p>
                        Log and Usage Data. We collect log data that may vary based on your interaction with us. This
                        data could include details about your activity in the Application, including the date and time
                        of your usage, which pages and files you viewed, and other actions you performed, such as which
                        features you used. Additionally, we may collect device event information, such as system
                        activity and error reports (also referred to as "crash dumps").
                    </p>
                    <p>
                        Bot, Assistant and skin Creation (UGC) 
                        When you create bots, assistants or skins within our Application, we collect data related 
                        to the creation process. This includes information such as the functionalities, scripts, 
                        commands, images, and configurations you input to develop and customize your bots, assistants and skins.
                    </p>
                    <p>
                        How we use your Information?
                        Facilitating the account creation and login process
                    </p>
                    <p>
                        Protecting our Services by utilizing your information as part of our efforts to prevent and
                        monitor fraudulent activities
                    </p>
                    <p>
                        Enforcing our terms, conditions, and policies for business purposes, complying with legal and
                        regulatory requirements or in connection with our contract
                    </p>
                    <p>
                        Responding to user inquiries and offering support to our users to address any potential issues
                        with the use of our Application.
                    </p>
                    <p>
                        Further information about the processing of personal data
                        Push notifications
                        This Application may send push notifications to the User to achieve the purposes outlined in
                        this privacy policy.
                    </p>
                    <p>
                        Users may in most cases opt-out of receiving push notifications by visiting their device
                        settings, such as the notification settings for mobile phones, and then change those settings
                        for this Application, some or all of the apps on the particular device.
                    </p>
                    <p>
                        Users must be aware that disabling push notifications may negatively affect the utility of this
                        Application.
                    </p>
                    <p>
                        Facilitating Bot and Assistant Creation 
                        We use the data collected during the bot, skin and assistant creation process to 
                        facilitate the development and customization of bots, skins and assistants (UGC) within our Application.
                    </p>
                    <p>
                        Improving User Experience
                        The information gathered helps us improve user experience by understanding user preferences and 
                        usage patterns related to bots, skins, assistants (UGC) creation.
                    </p>
                    <p>
                        Service Enhancement 
                        Data related to bots skins and assistants (UGC )creation may be utilized to enhance the functionality, 
                        features, and performance of our Application.
                    </p>
                    <p>
                        Analytics and Research
                        We may analyze aggregated data related to bots, skins and assistants (UGC) creation 
                        for research and analytical purposes to improve our services and offerings.
                    </p>
                    <p>
                        Sharing of your information
                        We may share anonymous or aggregate information with third parties, including advertisers and
                        investors. For instance, we may provide advertisers with the number of app users. This
                        information does not contain any personal or identifiable details, and is utilized solely for
                        the development of content and services that we hope will be of interest to you.
                    </p>
                    <p>
                        We may share your information, along with information from tools like log files, device
                        identifiers, and location data, with third-party organizations that assist us in providing the
                        Application to you ("Service Providers"). Our Service Providers will have access to your
                        information only as reasonably required to provide the Application, under reasonable
                        confidentiality terms.
                    </p>
                    <p>
                        We may be required by law to disclose your information in response to a judicial proceeding,
                        court order, or legal process, such as in response to a subpoena or government request. This may
                        include disclosing your information to public authorities to meet national security or law
                        enforcement requirements.
                    </p>
                    <p>
                        The data we collect is not used for tracking purposes. Tracking involves linking data collected
                        from a specific end-user or device (such as a user ID, device ID, or profile) with third-party
                        data for targeted advertising or advertising measurement purposes. It also refers to sharing
                        data collected from your app about a specific end-user or device with a data broker.
                    </p>
                    <p>
                        Accessing and deleting your data
                        You have the ability to control what information you share with us on the Application. If you
                        wish, you can also delete the Application from your mobile device at any time. You may request
                        that we delete your account and associated data within the mobile application from the settings
                        page. For more information, please refer to our support page guide. We will also make
                        commercially reasonable efforts to remove your data from our servers. It is important to note
                        that any personally identifiable information that you share with other users through the
                        Application, including through text messages, photos, videos, or other means, as well as any
                        information that you submit on a blog, bulletin board, or chat room, may be viewed and used by
                        others, potentially resulting in unsolicited messages or identity theft. We are not responsible
                        for any misuse of your information resulting from your disclosure of such information.
                    </p>
                    <p>
                        Data Deletion and Export Options    
                        You can at any time fully delete your data from our servers by using various delete options 
                        provided in the plaform. When you delete your account, all data associated with it, 
                        including profiles, images, bots, assistants, and skins, will be removed.
                        Additionally, you can request to export your current data related to bots, skins, and assistants you created 
                        by emailing us at neurolify@neurolify.com. You also have an export option in your account, 
                        which will export high-level data of the user. However, for full user-generated content, 
                        you need to email us and expect to hear back from us within the week or month
                    </p>
                    <p>
                        Service Providers
                        There may be occasions where we have agreements with third-party companies to offer products or
                        services through our Application. In these instances, we may share your personal information
                        with our third-party service providers, but only to the extent necessary to provide the services
                        to you.
                    </p>
                    <p>
                        Do we update this notice?
                        Yes, we will update this notice as necessary to comply with applicable laws. We may make updates
                        to this privacy notice periodically and the revised date will be displayed on the notice. The
                        updated version will be effective as soon as it is made accessible. If there are any significant
                        changes to this privacy notice, we may notify you by prominently posting a notice of the changes
                        or directly sending you a notification. We recommend that you review this privacy notice
                        regularly to stay informed about how we protect your information.
                    </p>
                    <p>
                        What are your privacy rights?
                        Withdraw their consent at any time. In essence, users have the right to revoke their consent if
                        they had previously given it for the processing of their Personal Data.
                    </p>
                    <p>
                        Object to processing of their Data. In brief, users have the right to object to the processing
                        of their data if it is carried out on a legal basis other than consent.
                    </p>
                    <p>
                        Access their Data. As a user, you have the right to know whether your personal data is being
                        processed by the data controller. You also have the right to access certain details about the
                        processing of your data and obtain a copy of the data that is being processed.
                    </p>
                    <p>
                        Verify and seek rectification. Users have the right to ensure the accuracy of their personal
                        information and request updates or corrections as necessary.
                    </p>
                    <p>
                        Restrict the processing of their Data. Under certain circumstances, users have the right to
                        request that the processing of their Data be restricted. In such cases, the Owner will only
                        store the data and will not process it for any other purpose.
                    </p>
                    <p>
                        Have their Personal Data deleted or otherwise removed. In certain situations, users have the
                        right to request the deletion or erasure of their data from the Owner's records.
                    </p>
                    <p>
                        Receive their Data and have it transferred to another controller. Under certain circumstances,
                        users have the right to obtain their personal data in a structured, commonly used, and
                        machine-readable format, and, if technically feasible, to have it transmitted to another
                        controller without hindrance. This right applies when the data is processed by automated means
                        and is based on the user's consent, a contract they are part of, or pre-contractual obligations.
                    </p>
                    <p>
                        Lodge a complaint. Users are entitled to file a complaint with their appropriate data protection
                        authority.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};


export default CleverAiPolicyPage;
