import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Header from "./components/Header/Header";
import MainPage from "./pages/Main/MainPage";
import Footer from "./components/Footer/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import AppsPage from "./pages/Apps/AppsPage";
import ContactPage from "./pages/Contact/ContactPage";
import CleverAiTermsPage from "./pages/CleverAiTerms/CleverAiTermsPage";
import CleverAiPolicyPage from "./pages/CleverAiPolicy/CleverAiPolicyPage";
import CleverAiPage from "./pages/CleverAi/CleverAiPage";
import CleverAiSupportPage from "./pages/CleverAiSupport/CleverAiSupportPage";

document.title = "Neurolify";
const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage/>,
    },
    {
        path: "/apps",
        element: <AppsPage/>,
    },
    {
        path: "/apps/clever-ai",
        element: <CleverAiPage/>,
    },
    {
        path: "/apps/clever-ai/privacy-policy",
        element: <CleverAiPolicyPage/>,
    },
    {
        path: "/apps/clever-ai/terms",
        element: <CleverAiTermsPage/>,
    },
    {
        path: "/apps/clever-ai/support",
        element: <CleverAiSupportPage/>,
    },
    {
        path: "/contact",
        element: <ContactPage/>,
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        {/*<ThemeProvider>*/}
        <div className="App">
            <Header/>
            <div className="App-content">
                <RouterProvider router={router}/>
            </div>
            <Footer/>
        </div>
        {/*</ThemeProvider>;*/}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
