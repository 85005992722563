import * as React from "react";
import './ContactPage.css';
import {Button, Col, Container, Row} from "react-bootstrap";


type OwnProps = {};

type Props = OwnProps;

const ContactPage: React.FC<Props> = props => {
    const {} = props;
    return (
        <Container fluid className="ContactPage">
            <Row className={'p-5'}>
                <Col>
                    <h1>Contact</h1>
                </Col>
            </Row>
            <Row className={'p-5'}>
                <Col>
                    <p>
                        If you have any questions or feedback about our products, please contact us at
                        neurolify@neurolify.com.
                    </p>
                    <p>
                        Our team is always happy to hear from you!
                    </p>
                    <p>
                        We are located in beautiful British Columbia, Canada.
                    </p>
                    <p>
                        <br/>Our office address is 3184 Capstan Crescent, Coquitlam, BC, Canada, V3C 4E3.
                    </p>
                    <div style={{marginTop: "40px"}}>
                        <Button href="mailto:neurolify@neurolify.com">
                            Send us an email
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};


export default ContactPage;
